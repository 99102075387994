<template>
  <div>
    <!-- confirmation dialog box -->
    <v-dialog v-model="manual_charge_dialog.flag" width="600">
      <v-card class="pa-5">
        <v-card-title class="headline flex-column lighten-2" primary-title>
          <v-icon color="warning" size="5rem"> mdi-check-decagram </v-icon>
          {{ $lang.CONFIRM_AMOUNT }}?
        </v-card-title>
        <v-card-text class="flex-column text-center mb-5">
          Confirm Manual Amount to charge
          <br />
          <strong class="text-center align-center mx-15 px-15">
            Note: Total overdue amount:

            {{ manual_charge_dialog.total_payable_amount }}
          </strong>
        </v-card-text>

        <v-row>
          <v-col cols="6">
            <v-select
              :items="payment_modes"
              v-model="payment_mode"
              label="Payment Mode"
              @change="getLedgerList"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="ledgers"
              v-model="ledger"
              label="Ledger"
              item-text="ledger_name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="payment_types"
              label="Payment Type"
              v-model="payment_type"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="290px"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="payment_date"
                  v-on="on"
                  :label="$lang.PAYMENT_DATE"
                  :placeholder="$lang.PAYMENT_DATE"
                  class="filter"
                  readonly
                >
                  <template slot="append"> </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="payment_date">
                <!-- :min="today"> -->
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="date_menu = false"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="formateDisplayDate"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field label="Amount" v-model="amount"> </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Remark" v-model="remark"> </v-text-field>
          </v-col>
        </v-row>

        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-btn class="fw-bold" color="light" width="80" @click="Close()">
            Cancel
          </v-btn>
          <v-btn
            :loading="btn_loading"
            class="fw-bold ml-5"
            color="primary"
            width="80"
            @click="charge"
          >
            Proceed
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ManualChargeConfirmDialog",
  props: ["manual_charge_dialog"],
  data() {
    return {
      payment_modes: ["Cash", "Bank"],

      ledger: null,
      ledgers: [],
      btn_loading: false,
      amount: null,

      remark: "",
      payment_date: null,
      payment_type: "Installment",
      payment_mode: null,
      payment_types: ["Installment"],
      date_menu: false,
      today: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    formateDisplayDate() {
      // this.payment_date = this.display_date_formate(this.payment_date);
      this.date_menu = false;
    },
    Close() {
      this.amount = 0;
      this.manual_charge_dialog.total_payable_amount = 0;
      this.manual_charge_dialog.flag = false;
    },
    getLedgerList() {
      const successHandler = (res) => {
        console.log(res.data, "LIST");
        this.ledgers = res.data;
      };
      this.request_GET(
        self,
        this.$urls.LEDGER_LIST + `?ledger_type=${this.payment_mode}`,
        {},
        successHandler,
        null,
        null,
        null
      );
    },

    charge() {
      const self = this;
      var data = {
        payment_type: "Installment",
        payment_mode: this.payment_mode,
        payment_date: this.payment_date,
        loan_id: this.manual_charge_dialog.loan_id,
        remark: this.remark,
        amount: this.amount,
        ledger_name: this.ledger,
      };
      console.log(data, "DATA");
      self.btn_loading = true;
      const successHandler = (response) => {
        self.manual_charge_dialog.flag = false;
        this.$emit("update");
      };
      const finallyHandler = () => {
        self.btn_loading = false;
        this.Close();
      };
      this.request_POST(
        self,
        this.$urls.CHARGE_MANUALLY_OTHER,
        data,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },
  mounted() {
    this.amount = this.manual_charge_dialog.total_payable_amount;
    console.log(this.amount);
  },
};
</script>

<style scoped>
.text-field {
  width: 150px !important;
  height: 40px;
  text-align: center;
  margin: 1px auto 10px auto;
  border: 1px solid #333;
}
</style>